/*!
 laypage默认样式
*/
.laypage_main a, .laypage_main input, .laypage_main span {
    height: 26px;
    line-height: 26px
}

.laypage_main button, .laypage_main input, .laypageskin_default a {
    border: 1px solid #ccc;
    background-color: #fff
}

.laypage_main {
    display: inline-block;
    font-size: 0;
    clear: both;
    color: #666
}

.laypage_main * {
    display: inline-block;
    vertical-align: top;
    font-size: 12px
}

.laypage_main a {
    text-decoration: none;
    color: #666
}

.laypage_main a, .laypage_main span {
    margin: 0 3px 6px;
    padding: 0 10px
}

.laypage_main input {
    width: 50px;
    margin: 0 5px;
    padding: 0 5px;
    color: #000;
}

.laypage_main button {
    height: 26px;
    line-height: 26px;
    margin-left: 5px;
    padding: 0 10px;
    color: #666;
    font-size: 12px;
}

.laypageskin_default span {
    /*height: 28px;*/
    /*line-height: 28px;*/
    /*color: #999*/
}

.laypageskin_default .laypage_curr {
    border: 1px solid #FF5001;
    background: #fff;
    color: #FF5001;
}

.laypageskin_molv a, .laypageskin_molv span {
    padding: 0 12px;
    border-radius: 2px
}

.laypageskin_molv a {
    background-color: #f1eff0
}

.laypageskin_molv .laypage_curr {
    background-color: #00AA91;
    color: #fff
}

.laypageskin_molv input {
    height: 24px;
    line-height: 24px
}

.laypageskin_molv button {
    height: 26px;
    line-height: 26px
}

.laypageskin_yahei {
    color: #333
}

.laypageskin_yahei a, .laypageskin_yahei span {
    padding: 0 13px;
    border-radius: 2px;
    color: #333
}

.laypageskin_yahei .laypage_curr {
    background-color: #333;
    color: #fff
}

.laypageskin_flow {
    text-align: center
}

.laypageskin_flow .page_nomore {
    color: #999
}

body {
    background: #fff;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333;
    min-width: 768px !important;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #ccc
}

/* ==========================================================================
    伸缩侧边css代码
========================================================================== */
.sidebar-collapse .sidespan {
    display: none;
}

.sidebar-collapse .sidebar1 {
    width: 50px;
}

.sidebar-collapse .sidebar1 li {
    position: relative;
    z-index: 101;
    text-align: center;
}

.sidebar-collapse .sidebar1 li a {
    padding: 0 14px;
}

.sidebar-collapse .sidebar1 li:hover a {
    border-left: 2px solid #FF5001;
    background: #262626;
    text-decoration: none;
}

.sidebar-collapse .sidebar2 {
    left: 50px;
}

.sidebar-collapse .sidebar2-shrink {
    left: 150px;
}

.sidebar-collapse2 .sidebar2 {
    left: 20px;
}

.sidebar-collapse2 .sidebar2-shrink {
    left: 120px;
}

.sidebar-collapse3 .sidebar2 {
    left: 120px;
}

.sidebar-collapse3 .sidebar2-shrink {
    left: 111px;
}

.sidebar-collapse4 .sidebar2 {
    left: -50px;
}

.sidebar-collapse4 .sidebar2-shrink {
    left: 50px;
}

/* ==========================================================================
navbar in sidebar1,sidebar2
========================================================================== */
.side {
    display: block !important;
}

.sidebar1 {
    left: 0;
    top: 60px;
    position: fixed;
    width: 120px;
    height: 100%;
    background: #3b3b3b;
    transition: all .5s ease;
    z-index: 105;
}

.sidebar1 li a {
    display: block;
    color: #fff;
    font-size: 14px;
    border-left: 2px solid transparent;
    transition: .3s linear;
    padding: 0 10px 0 26px;
}

.sidebar1 > li > a > i {
    margin-right: 10px;
}

.sidebar1 .hover, .sidebar1 li a:hover {
    border-left: 2px solid #FF5001;
    background: #262626;
    text-decoration: none;
}

.sidebar1 li {
    height: 56px;
    line-height: 56px;
    white-space: nowrap;
}

.sidebar2 {
    position: fixed;
    left: 120px;
    top: 60px;
    bottom: 0;
    width: 100px;
    background-color: #fff;
    /*-webkit-overflow-scrolling: touch;*/
    /*-webkit-overflow-scrolling: -blackberry-touch;*/
    overflow-x: hidden;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 102;
}

.sidebar2-zIndex {
    z-index: 0;
}

.transition {
    transition: all .5s ease;
}

.sidebar1 ul, .sidebar2 ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.sidebar2 ul {

}

.sidebar2 li {
}

/*.sidebar li span { color: #6d7983; }*/
.sidebar2 li .caret {
    margin-top: 9px;
}

.sidebar2 li i {
    /*margin-right: 15px;*/
}

.sidebar2 .nav-header {
    padding: 10px;
    background-color: #f5f5f5;
}

.sidebar2::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.arrows {
    z-index: 1001;
    position: absolute;
    right: 20px;
    border: 1px solid #c8c8c8;
    border-width: 1px 1px 0 0;
    display: inline-block;
    height: 8px;
    -moz-transform-origin: center center;
    -moz-transform: rotate(45deg);
    -moz-transition: all .3s ease-in .1s;
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(45deg);
    -webkit-transition: all .3s ease-in .1s;
    -o-transform-origin: center center;
    -o-transform: rotate(45deg);
    -o-transition: all .3s ease-in .1s;
    -ms-transform-origin: center center;
    -ms-transform: rotate(45deg);
    -ms-transition: all .3s ease-in .1s;
    transform-origin: center center;
    transform: rotate(45deg);
    transition: all .3s ease-in .1s;
    width: 8px;
    margin-top: 6px;
}

.arrowsTop {
    z-index: 1001;
    position: absolute;
    right: -16px;
    top: 8px;
    border: 1px solid #c8c8c8;
    border-width: 1px 1px 0 0;
    display: inline-block;
    height: 8px;
    -moz-transform-origin: center center;
    -moz-transform: rotate(135deg);
    -moz-transition: all .3s ease-in .1s;
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(135deg);
    -webkit-transition: all .3s ease-in .1s;
    -o-transform-origin: center center;
    -o-transform: rotate(135deg);
    -o-transition: all .3s ease-in .1s;
    -ms-transform-origin: center center;
    -ms-transform: rotate(135deg);
    -ms-transition: all .3s ease-in .1s;
    transform-origin: center center;
    transform: rotate(135deg);
    transition: all .3s ease-in .1s;
    width: 8px;
    /*margin-top: 6px;*/
}

.arrowHover:hover b {
    -moz-transform: rotate(-5deg);
    -moz-transition: all .2s ease-in .1s;
    -webkit-transform: rotate(-45deg);
    -webkit-transition: all .2s ease-in .1s;
    -o-transform: rotate(-45deg);
    -o-transition: all .2s ease-in .1s;
    -ms-transform: rotate(-45deg);
    -ms-transition: all .2s ease-in .1s;
    transform: rotate(-45deg);
    transition: all .2s ease-in .1s;
    top: 12px;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar {
    padding: 0;
    margin: 0;
    border: none;
}

.navbar-top {
    background: #fff;
    line-height: 28px;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #ddd;
    outline: none;
    z-index: 999;
}

.navbar-logo {
    outline: none;
    float: left;
    width: 120px;
    height: 60px;
    /*padding: 24px 0;*/
    /*background: #3b3b3b url("../images/logo.png") no-repeat 15px center;*/
}

.navbar-logo3 {
    outline: none;
    float: left;
    width: 50px;
    height: 60px;
    padding: 24px 0;
    background: #3b3b3b;
}

.sidebar1-shrink {
    height: 20px;
    text-align: center;
    background: #262626;
    margin-bottom: 2px;
    outline: none;
}

.logo-text {
    color: #fff;
    font-size: 28px;
    margin-left: 33px;
    outline: none;
}

#account-menu {
    border: 1px solid #ddd;
    border-radius: 5px;
    height: 50px;
    width: 110px;
    line-height: 48px;
    text-align: center;

}

.modele-view{
    float: right;
    height: 65px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s;
}
.modele-view img{
    height: 25px;
}
.modele-view:hover{
    color: #FF5001;
}
.navbar-setting {
    float: right;
    margin-right: 20px;
    display: block !important;
    height: 60px !important;
}

.navbar-user {
    float: right;
    margin-top: 5px;
}

.users {
    display: block;
    margin-top: -18px;
    width: 48px;
    text-align: center;
}

.users-a {
    text-align: center;
    font-size: 12px;
    color: #3e3e3e;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 70px;
    /*display: inline-block;*/
}

.users-a:hover {
    color: #FF5001;
}

.arrows-icon {
    width: 8px;
    height: 8px;
    padding: 8px;
    background: url("../images/icon/arrow.png") no-repeat center;
}

.user {
    display: block;
    background: #fff;
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.navbar-language {
    float: right;
    margin-left: 20px;
}

.glyphicons {
    background: url("../images/model-icon.png") no-repeat center;
    display: inline-block;
    width: 14px;
    height: 14px;
    padding-left: 40px;
}

.glyphicons-sj {
    background: url("../images/icon/data.png") no-repeat center;
    display: inline-block;
    width: 20px;
    height: 18px;
    margin-right: 10px;
}

.hide-sm {
    color: #c8c8c8;
}

.hide-sm:focus {
    color: #FF5001;
}

.navbar-title:hover {
    background-color: #464c54 !important;
    color: #fff;
}

.menu1 {
    background: #fff;
    display: none;
}

.menu2 {
    /*margin: 0 6px 6px;*/
    /*border-left: 4px solid #313840;*/
    /*border-right: 4px solid #313840;*/
    outline: none;
    text-align: left;
    padding-left: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.menu2-text {
    color: #3e3e3e;
    font-size: 12px;
    line-height: 55px;
    padding: 0;
}

.menu2:hover, .active {
    background: #fff2f1 !important;
    text-decoration: none;

}

.menu2:hover .menu2-text, .active .menu2-text {
    color: #FF5001 !important;
    text-decoration: none !important;
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.hipster {
    display: inline-block;
    width: 347px;
    height: 497px;
    background: url("../images/hipster.png") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (   min--moz-device-pixel-ratio: 2), only screen and (     -o-min-device-pixel-ratio: 2/1), only screen and (        min-device-pixel-ratio: 2), only screen and (                min-resolution: 192dpi), only screen and (                min-resolution: 2dppx) {
    .hipster {
        background: url("../images/hipster2x.png") no-repeat center top;
        background-size: contain;
    }
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break: break-all;
}

.voffset {
    margin-top: 2px;
}

.voffset1 {
    margin-top: 5px;
}

.voffset2 {
    margin-top: 10px;
}

.voffset3 {
    margin-top: 15px;
}

.voffset4 {
    margin-top: 30px;
}

.voffset5 {
    margin-top: 40px;
}

.voffset6 {
    margin-top: 60px;
}

.voffset7 {
    margin-top: 80px;
}

.voffset8 {
    margin-top: 100px;
}

.voffset9 {
    margin-top: 150px;
}

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
    cursor: pointer;
}

.hand {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display: inline;
    list-style: none;
    margin: 0;
    margin-left: 15px;
    padding: 0;
    vertical-align: 2px;
}

.point:last {
    margin: 0 !important;
}

.point {
    background: #DDD;
    border-radius: 2px;
    display: inline-block;
    height: 5px;
    margin-right: 1px;
    width: 20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert {
    text-overflow: ellipsis;
}

.alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.no-padding-top {
    padding-top: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
    width: 1% !important;
}

.width-80 {
    width: 80px !important;
}

.mar-left-30 {
    margin-left: 30px;
}

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td vertically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
    outline: none;
}

/* jhipster-needle-css-add-main JHipster will add new css style */
#account-menu2 {
    border: 1px solid #dedede;
    border-radius: 5px;
    height: 30px;
    width: 100px;
    line-height: 28px;
    text-align: center;
}

.bar1-active {
    outline: none;
}

/*home*/
.list-order {
    width: 420px;
    height: 188px;
    float: left;
    margin-right: 20px;
    background: #fff;
    padding: 20px 30px 30px 20px;
}

.number-1 {
    font-size: 14px;
    color: #CBCBCB;
}

.number-2 {
    font-size: 26px;
    color: #232323;
    margin-top: 10px;
    margin-bottom: 10px;
}

.home-title {
    font-size: 22px;
    margin: 30px 0;
    color: #232323;
}

.platform-dynamic {
    width: 100%;
}

.dynamic {
    width: 310px;
    height: 240px;
    background: #fff;
    padding: 20px 10px 0;
    float: left;
    margin-right: 20px;
}

.left-dynamic {
    width: 90px;
    text-align: center;
    float: left;
}

.right-dynamic {
    width: 180px;
    float: left;
}

.dynamic-title {
    color: #232323;
    font-size: 16px;
}

.dynamic-content {
    width: 100%;
}

.dynamic-ing {
    display: inline-block;
    font-size: 12px;
    color: #CBCBCB;
    margin: 20px 0 10px;
}

.dynamic-number {
    color: #232323;
    font-size: 24px;
    margin-bottom: 10px;
}

.left-content-50 {
    float: left;
    width: 50%;
}

.left-content-100 {
    width: 100%;
}

.nav-list {
    width: 100%;
}

.nav-item {
    float: left;
    margin-right: 20px;
    width: 315px;
    background: #fff;
    height: 66px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 66px;
    cursor: pointer;
}

.nav-name {
    display: inline-block;
    margin-left: 6px;
    font-size: 14px;
    color: #232323;
}

ul {
    margin: 0;
    padding: 0;
}

.canvas {
    width: 100%;
    height: 60px;
    background-size: 100% 100%;
}

.canvas1 {
    background: url(../images/orders.svg) no-repeat bottom center;
}

.canvas2 {
    background: url(../images/cards.svg) no-repeat bottom center;
}

.canvas3 {
    background: url(../images/sales.svg) no-repeat bottom center;
}

.list-status {
    width: 100%;
    height: 40px;
    line-height: 40px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 5px;
}

.main-status {
    width: 100%;
    height: 40px;
    padding: 0 30px;
}

.main-status li {
    float: left;
    height: 40px;
    margin-right: 40px;
}

.main-status li a {
    text-decoration: none;
    color: #979797;
    font-size: 14px;
}

.colorSelect {
    border-bottom: 2px solid #FF5001;
    background: #fff !important;
}

.colorSelect a {
    color: #FF5001 !important;
}

.screeningConditions {
    width: 100%;
    height: auto;
    background: #fff;
    border-radius: 5px;
    padding: 0px 30px;
    margin-bottom: 20px;
}

.screeningTitle {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #e9e9e9;
}

.screen-1 {
    font-size: 16px;
    color: #232323;
}

.screen-2 {
    color: #FF5001;
    font-size: 12px;
    display: inline-block;
    margin-left: 10px;
    outline: none;
    cursor: pointer;
}

.screen-3 {
    font-size: 16px;
    color: #000;
}

.form-control-edit {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    font-size: 14px;
    line-height: 40px;
    outline: none;
    color: #555555;
    background-color: #fff;
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.fontStyle {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #232323;
    text-align: right;
    font-weight: normal !important;
    margin-bottom: 0 !important;
}

.button-operate {
    width: 65px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    border-radius: 5px;
    font-size: 14px;
    outline: 0 none;
    display: inline-block;
    margin-bottom: 20px;
    cursor: pointer;
}

.screen {
    background: #FF5001;
    color: #fff;
    margin-left: 30px;
}

.reset {
    background: #fff;
    color: #232323;
    border: 1px solid #979797;
    margin-left: 20px;
}

.pad-top-20 {
    padding-top: 20px;
}

.main-table th, .main-table > tbody > tr > td {
    height: 54px !important;
    font-size: 14px;
    color: #232323;
    font-weight: normal !important;
    text-align: center !important;
    padding: 0 !important;
}

.main-table > thead > tr > th {
    border-bottom: none !important;
    line-height: 54px !important;
}

.line-vertical {
    color: #898989;
    width: 0;
    height: 0;
}

.btn-fff {
    background: #fff !important;
    border: none;
    border-radius: 4px !important;
}

.input-checkbox {
    width: 20px;
    height: 20px;
    -webkit-appearance: none;
    outline: none;
    background: url("../images/icon/choose_none.svg") no-repeat center;
}

.input-checkbox:checked {
    background: url("../images/icon/choose_sel.svg") no-repeat center;
    outline: none;
}

.text-jump {
    display: none;
}

.color-A8A8A8 {
    color: #A8A8A8;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-weight: normal !important;
    margin-bottom: 0 !important;
}

.data-helper {
    width: 100%;
    height: 230px;
    background: #fff;
    margin-bottom: 20px;
}

.helper-left {
    float: left;
    padding: 16px 0;
    text-align: center;
    width: 300px;
}

.helper-title {
    font-size: 14px;
    color: #232323;
    margin-top: 12px;
}

.helper-right {
    float: left;
    width: 600px;
    padding-top: 40px;
}

.helper-content {
    font-size: 11px;
    color: #232323;
    width: 500px;
    height: 100px;
    padding: 10px;
    line-height: 22px;
    position: relative;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.helper-content em {
    display: block;
    border-width: 10px;
    position: absolute;
    left: -20px;
    top: 30px;
    border-style: solid dashed dashed;
    border-color: transparent #ccc transparent transparent;
    font-size: 0;
    line-height: 0;
}

.helper-content .tip {
    display: block;
    border-width: 10px;
    position: absolute;
    left: -19px;
    top: 30px;
    border-style: solid dashed dashed;
    border-color: transparent #FFF transparent transparent;
    font-size: 0;
    line-height: 0;
}

.case-title {
    font-size: 20px;
    color: #232323;
    padding: 25px 30px;
    border-bottom: 1px solid #ccc;
}

.case-list {
    width: 100%;
    padding: 40px 30px;
}

.case-list li {
    width: 200px;
    height: 356px;
    float: left;
    margin-right: 74px;
}

.case-list li img {
    width: 100%;
    height: 100%;
}

.tag {
    width: 300px;
    height: 100px;
    border: 1px solid #ccc;
    position: relative;
}

.content-scroll::-webkit-scrollbar, .scrollbar::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
}

/* 这是针对缺省样式 (必须的) */
.content-scroll::-webkit-scrollbar-track, .scrollbar::-webkit-scrollbar-track {
    background-color: #fff;
}

/* 滚动条的滑轨背景颜色 */

.content-scroll::-webkit-scrollbar-thumb, .scrollbar::-webkit-scrollbar-thumb {
    background-color: #dadada;
    border-radius: 20px;
    border: 2px solid #fff;
}

/* 滑块颜色 */

.content-scroll::-webkit-scrollbar-button, .scrollbar::-webkit-scrollbar-button {
    display: none;
    background-color: #fff;
}

/* 滑轨两头的监听按钮颜色 */

.content-scroll::-webkit-scrollbar-corner, .scrollbar::-webkit-scrollbar-corner {
    background-color: #fff;
}

.btn-gener {
    background: #FF5001;
    height: 25px;
    line-height: 25px;
    border: none;
    border-radius: 2px;
    color: #fff;
    font-size: 12px;
}

.modal-property {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
}

.modal-mask {
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
}

.modal-con {
    width: 40vw;
    margin: 30vh auto;
    height: 60vh;
    overflow: auto;
    min-height: 40vh;
    background: #fff;
    border-radius: 5px;
    position: relative;
    text-align: center;
}

.modal-con-big {
    width: 50vw;
    margin: 25vh auto;
    height: 60vh;
    overflow: auto;
    min-height: 40vh;
    background: #fff;
    border-radius: 5px;
    position: relative;
    text-align: center;
}

.modal-t {
    border-bottom: 1px solid #d9d9d9;
    padding: 16px 0;
    width: 40vw;
    position: fixed;
    background: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 99;
}

.modal-default {
    font-size: 14px;
    color: #232323;
    margin-top: 25px;
    font-weight: 600;
}

.modal-foot {
    border-top: 1px solid #d9d9d9;
    width: 40vw;
    height: 70px;
    position: fixed;
    background: #fff;
    z-index: 99;
    bottom: 10vh;
}

.modal-foot button {
    margin: 20px 15px;
    padding: 0 5px;
}

.upload-text {
    position: absolute;
    color: #FF5001;
    border-bottom: 1px solid;
    left: 30%;
}

.overflow-h{
    overflow: hidden!important;
}

.tip-mask{
    margin-left: 60px;
    color: #FF5001;
}



.c-statistics {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    overflow: hidden;
}
.c-statistics .c-statistics-view {
    height: 120px;
    line-height: 120px;
    display: flex;
    cursor: pointer;
    position: relative;
}

.c-statistics-view-wrap {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 120px;
    padding-left: 30px;
}

.c-statistics-view-wrap p{
    font-size: 18px;
    font-weight: normal;
    color: #EC4B13;
}


.c-statistics-view-wrap span{
    font-size: 36px;
    font-weight: normal;
    color: #EC4B13;
    display: inline-block;
    padding-left: 56px;
}

.c-statistics-view-bg {

}
.uibtabs {
    padding-top: 10px;
    padding-left: 10px;
}

.uibtabs ul li a {
    margin-right: 2px;
    /* line-height: 1.42857143; */
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}

.uibtabs .nav-tabs li a {
    margin-right: 2px;
    /* line-height: 1.42857143; */
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}

.uibtabs .nav-tabs li.active a, .uibtabs  .nav-tabs li.active a:hover, .uibtabs .nav-tabs li.active a:focus {
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
}

.uibtabs ul .active {
    background: #fff !important;
}



.uibtabs .nav .nav-item {
    float: left;
    margin-right: 3px;
    width: 200px;
    background: #fff;
    height: 35px;
    margin-bottom: 5px;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
}
.uibtabs .nav i a {
    position: relative;
    display: block;
 }


.diy-editor-head{
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px #D7DBE2;
    text-align: right;
    position: absolute;
    z-index: 1;
}

.diy-editor-head-cancel{
    width: 100px;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    color: #C4C4C4;
    line-height: 50px;
    border: none;
    border-left: 1px solid #EDEDED;
    padding-left: 10px;
    background: none;
}

.diy-editor-head-save{
    width: 110px;
    height: 50px;
    line-height: 50px;
    background: #FF5101;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    letter-spacing: 20px;
    border: none;
    padding-left: 30px;
}

.diy-editor-poster{
    width: 375px;
    min-height: 705px;
    margin-left: calc(50% - 200px);
}

.diy-editor-poster-box{
    padding: 10px 0;
    height: 75px;
}

.diy-editor-poster-box-edit{
    height: 55px;
    border-radius: 4px;
    margin: 0 auto;
    border: 1px solid #979797;
    text-align: center;
    padding: 10px 0;
    color: #333;
    position: relative;
    width: 60px;
}

.diy-editor-poster-box-edit img{
    margin-bottom: 8px;
}

.diy-editor-poster-box-edit-del{
    width: 16px;
    position: absolute;
    top: -8px;
    right: -8px;
}

.diy-editor-poster-box-edit-active{
    border: 1px solid #FFBC9D!important;
}

.diy-editor-poster-box-edit2{
    height: 60px;
    background: #D8D8D8;
    border-radius: 4px;
    opacity: 0.38;
    text-align: center;
    font-size: 12px;
    color: #353535;
    /*padding: 0;*/
    /*margin: 0 14px;*/
}

.diy-editor-poster-box-edit2 img{
    margin: 16px 0 8px;
}

.diy-editor-head-left{
    float: left;
    padding: 24px 22px 24px 16px;
    width: 250px;
}

.diy-editor-head-left-title{
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #474747;
    line-height: 22px;
    margin-bottom: 24px;
    text-align: left;
}

.diy-editor-head-left-ul{

}

.diy-editor-head-left-ul li{
    width: 212px;
    height: 141px;
    border-radius: 4px;
    border: 2px solid #E9EAEC;
    margin-bottom: 36px;
    font-size: 18px;
    color: #6B7075;
    text-align: center;
}

.diy-editor-head-left-ul img{
    width: 44px;
    height: 44px;
    margin: 29px 0 18px;
    cursor: pointer;
}

.diy-editor-head-right{
    float: right;
    width: 360px;
    background: #FFFFFF;
    /*box-shadow: -3px 0px 17px 0px rgba(26, 51, 78, 0.15);*/
    padding: 29px 26px;
    text-align: left;
}

.diy-editor-head-right-title{
    height: 22px;
    font-size: 16px;
    font-weight: 600;
    color: #3F4245;
    line-height: 22px;
    margin-bottom: 42px;
}
.diy-editor-head-right-title2{
    height: 22px;
    font-size: 16px;
    font-weight: 600;
    color: #3F4245;
    line-height: 22px;
    margin-bottom: 18px;
}
.diy-editor-head-right-title3{
    height: 22px;
    font-size: 16px;
    font-weight: 500;
    color: #595959;
    line-height: 22px;
    margin-bottom: 8px;
}

.diy-editor-head-right-btn-gray{
    width: 135px;
    height: 40px;
    background: #F5F5F5;
    border-radius: 4px;
    font-size: 14px;
    color: #484848;
    line-height: 40px;
    border: none;
}

.diy-editor-head-right-btn-active{
    width: 135px;
    height: 40px;
    background: #FFE8D6;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    color: #FF5101;
    border: none;
    position: relative;
}

.diy-editor-choose{
    width: 22px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.diy-editor-right-input{
    width: 224px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #DDDDDD;
}
.diy-editor-right-input2{
    width: 196px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #DDDDDD;
    font-size: 14px;
    color: #6F6F6F;
    line-height: 32px;
    margin-left: 10px;
    vertical-align: top;
    text-indent: 10px;
    display: inline-block;
}

.diy-editor-head-center{
    float: left;
    width: calc(100% - 610px);
    background-color: #ECEFF2;
    padding-top: 68px;
}

.diy-editor-right-chooseColor{
    width: 65px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #EDEDED;
    cursor: pointer;
    padding: 0;
    border: none;
    opacity: 0;
    position: absolute;
    left: 0;
}

.diy-editor-head-right-add{
    width: 245px;
    height: 32px;
    border-radius: 4px;
    border: 1px dashed #DDDDDD;
    font-size: 14px;
    color: #666666;
    line-height: 32px;
    text-align: center;
}

.diy-editor-head-right-add img{
    margin-right: 33px;
}

.diy-editor-poster-top{
    width: 375px;
    height: 42px;
    background: #fff;
}

.diy-editor-poster-top2{
    width: 375px;
    height: 47px;
    background-color: #F3F3F3;
    text-align: right;
    padding-top: 9px;
}

.diy-editor-poster-top2 img{
    width: 16px;
    height: 16px;
}

.c-productThree-btn2{
    width: 100px;
    height: 30px;
    line-height: 30px;
    border-radius: 20px;
    color: #fff;
    font-size: 12px;
    background: #ff6e33;
    border: none;
    margin: 0 10px;
}

.diy-editor-poster-pic{
    width: 375px;
    height: 571px;
    background: #D8D8D8;
    font-size: 60px;
    font-weight: 600;
    color: #3F4245;
    line-height: 571px;
    text-align: center;
}

.diy-editor-poster-edit{
    min-height: 70px;
    margin-bottom: 10px;
    overflow: hidden;
    margin-top: 20px;
}

.diy-drop-active{
    outline: 1px dashed #ccc;
    background-color: rgba(204,204,204,0.6);
}

.vertical-align-top{
    vertical-align: top;
}

.diy-editor-head-right-sel{
    width: 245px!important;
    margin-bottom: 10px;
}

.diy-editor-head-right-del{
    position: absolute;
    color: red;
    right: 30px;
    top: 10px;
}

.pad-top-50{
    padding-top: 50px;
}
.flex-btn-group-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}
.flex-1 {
    flex: 1;
    -webkit-flex: 1;
    padding: 5px 0;
    text-align: center;
}
.leftPanel-bg-choose-color {
    cursor: pointer;
    width: 28px;
    padding: 0;
    height: 32px;
    border: none;
    /*margin-left: 10px;*/
    opacity: 0;
    position: absolute;
    left: 10px;
}
.ui-pnotify{
    z-index: 999999 !important;
}
.border-radius5{
    border-radius: 5px;
}


hr {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.wrap {
    /*width: 100% !important;*/
    margin: 35px 0 0 60px;
    transition: all .5s ease;
}

.wrap-mar60 {
    margin-left: 60px !important;
}

.wrap-mar130 {
    margin-left: 130px !important;
}

.wrap-mar160 {
    margin-left: 160px !important;
}

.wrap-mar190 {
    margin-left: 190px !important;
}

.wrap-mar220 {
    margin-left: 220px !important;
}

.outline_no {
    outline: none !important;
}

.login_div {
    margin-top: 110px;
    bottom: 0;
    right: 0;
    left: 38%;
    top: 50%;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.5) !important;
    background-color: #000;
    filter: Alpha(opacity=50);
}

.positionA {
    position: absolute;
    bottom: -16px;
    left: 0;
}

.login-icon {
    /*background-image: url('../images/boostphoto_logo.png');*/
    background-repeat: no-repeat !important;
    background-position: center !important;
    height: 63px;
}

.login_account {
    height: 50px;
    width: 54px;
    background-color: #fff;
}

.login_footer {
    margin-top: 35px;
    text-align: center;
    font-size: 14px;
    color: #fff;
}

.text-align-center {
    text-align: center;
}

.body {
    /*background-image:url('../images/loginbg.jpg'); */
    /*background-size: 100% auto !important; */
    /*background-repeat:no-repeat !important;*/
    /*background-position:center !important;*/
    /*background-attachment:fixed !important;*/
}

.login_bgi {
    background-image: url('../images/beijing.jpg');
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    overflow: hidden;
}

.transparentBGC {
    background-color: rgba(0, 0, 0, .0001) !important;
    border: 0px !important;
    padding: 0px !important;
}

.moduleNavbarColor {
    color: rgb(216, 214, 214) !important;
}

.jh-table > tbody > tr > td > a {
    color: #00a2ff !important;
}

.white {
    color: white !important;
}

.black {
    color: #555 !important;
}

.pad-0 {
    padding: 0px !important;
}

.pad-5 {
    padding: 5px !important;
}

.pad-10 {
    padding: 10px !important;
}

.pad-left-10 {
    padding-left: 10px !important;
}

.pad-right-10 {
    padding-right: 10px !important;
}

.pad-left-0 {
    padding-left: 0 !important;
}

.pad-left-20 {
    padding-left: 20px !important;
}

.pad-right-20 {
    padding-right: 20px !important;
}

.pad-left-30 {
    padding-left: 30px !important;
}

.pad-left-50 {
    padding-left: 50px !important;
}

.pad-right-30 {
    padding-right: 30px !important;
}

.pad-right-50 {
    padding-right: 50px !important;
}

.pad-right-5 {
    padding-right: 5px !important;
}

.pad-left-5 {
    padding-left: 5px !important;
}

.pad-right-0 {
    padding-right: 0px !important;
}

.pad-top-10 {
    padding-top: 10px !important;
}

.pad-top-60 {
    padding-top: 60px !important;
}

.pad-bottom-10 {
    padding-bottom: 10px !important;
}

.pad-bottom-20 {
    padding-bottom: 20px !important;
}

.pad-top-0 {
    padding-top: 0px !important;
}

.pad-bottom-0 {
    padding-bottom: 0px !important;
}

.pad-top-7 {
    padding-top: 7px;
}

.text-left {
    text-align: left !important;
}

.accounts-head {
    height: 60px;
    margin-top: 30px;
}

.mar-5 {
    margin: 5px !important;
}

.mar-10 {
    margin: 10px !important;
}

.mar-15 {
    margin: 15px !important;
}

.mar-top-20 {
    margin-top: 20px !important;
}

.mar-top-25 {
    margin-top: 25px !important;
}

.mar-top-10 {
    margin-top: 10px !important;
}

.mar-top-15 {
    margin-top: 15px !important;
}

.mar-top-30 {
    margin-top: 30px !important;
}

.mar-top-40 {
    margin-top: 40px !important;
}

.mar-top-60 {
    margin-top: 60px !important;
}

.mar-tottom-0 {
    margin-bottom: 0px !important;
}

.mar-right-0 {
    margin-right: 0px !important;
}

.mar-right-10 {
    margin-right: 10px !important;
}

.mar-right-30 {
    margin-right: 30px !important;
}

.mar-right-10p {
    margin-right: 10%;
}

.mar-right-20p {
    margin-right: 20%;
}

.mar-left-0 {
    margin-left: 0px !important;
}

.mar-left-15i {
    margin-left: 15px !important;
}

.text-align-center {
    text-align: center;
}

.mar-left-106 {
    margin-left: 106px !important;
}

.mar-left-10p {
    margin-left: 10%;
}

.mar-left-20p {
    margin-left: 20%;
}

.mar-left-10 {
    margin-left: 10px !important;
}

.mar-left-80 {
    margin-left: 80px !important;
}

.mar-left-100 {
    margin-left: 100px !important;
}

.mar-bottom-10 {
    margin-bottom: 10px !important;
}

.mar-bottom-15 {
    margin-bottom: 15px !important;
}

.mar-bottom-20 {
    margin-bottom: 20px !important;
}

.mar-bottom-30 {
    margin-bottom: 30px !important;
}

.mar-bottom-40 {
    margin-bottom: 40px !important;
}

.mar-bottom-70 {
    margin-bottom: 70px !important;
}

.jh-table > thead > tr {
    background-color: #fafafa !important;
}

.jh-table {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}

.jh-table > tbody > tr:nth-of-type(odd) {
    background-color: #fff !important;
}

.jh-table > tbody > tr:nth-of-type(even) {
    background-color: #fff !important;
}

.listBGC {
    background-color: #f1f2f7 !important;
    margin: -25px 0 0 0;
    padding: 20px;
    position: relative;
}

.current-location {
    margin-bottom: 20px;
    /*position: fixed;*/
    /*height: 50px;*/
}

.sidebar2-shrink {
    position: fixed;
    top: 96px;
    left: 211px;
    z-index: 101;
    transition: all .5s ease;
}

.shidebar2-shrink-left {
    left: 220px;
}

.shidebar2-shrink-left2 {
    left: 120px !important;
}

.sidebar1-btn {
    outline: none;
    border: none;
    width: 21px;
    height: 12px;
}

.sidebar2-btn {
    border: none;
    width: 14px;
    height: 36px;
    outline: none;
}

.btn1-shrik1 {
    background: url("../images/icon/menu-shrink1.png") no-repeat center;
}

.btn1-shrik2 {
    background: url("../images/icon/menu-open1.png") no-repeat center;
}

.btn2-shrik1 {
    background: url("../images/icon/menu-shrink2.png") no-repeat center;
}

.btn2-shrik2 {
    background: url("../images/icon/open-menu.png") no-repeat center;
}

.current-menu {
    color: #FF5001 !important;
    border-bottom: 3px solid #FF5001 !important;
}

.con {
    padding: 20px 30px;
    background: #fff;
    border-radius: 5px;
}

.textArea {
    resize: none;
    border-radius: 4px;
}

.moduleName {
    font-size: 22pt !important;
    color: rgb(5, 206, 201) !important;
}

.moduleNameBGC {
    background-color: rgb(75, 75, 80) !important;
    height: 30px;
}

.readLabel {
    padding-top: 0px !important;
}

/*.form-group > div > a {
    color:white !important;
}*/
.subitemBGC {
    background-color: rgb(132, 132, 124);
}

.propertyBGC {
    background-color: rgb(114, 108, 96);
}

.productHr {
    width: 100%;
    height: 3px;
    border: none;
    border-top: 3px solid rgb(140, 140, 138);
}

.radius-10 {
    border-radius: 10px;
}

.fw1 {
    width: 1em !important;
    min-width: 1em !important;
    max-width: 1em !important;
}

.fw2 {
    width: 2em !important;
    min-width: 2em !important;
    max-width: 2em !important;
}

.fw3 {
    width: 3em !important;
    min-width: 3em !important;
    max-width: 3em !important;
}

.fw4 {
    width: 4em !important;
    min-width: 4em !important;
    max-width: 4em !important;
}

.fw5 {
    width: 5em !important;
    min-width: 5em !important;
    max-width: 5em !important;
}

.fw6 {
    width: 6em !important;
    min-width: 6em !important;
    max-width: 6em !important;
}

.fw7 {
    width: 7em !important;
    min-width: 7em !important;
    max-width: 7em !important;
}

.fw8 {
    width: 8em !important;
    min-width: 8em !important;
    max-width: 8em !important;
}

.fw9 {
    width: 9em !important;
    min-width: 9em !important;
    max-width: 9em !important;
}

.fw10 {
    width: 10em !important;
    min-width: 10em !important;
    max-width: 10em !important;
}

.fw12 {
    width: 12em !important;
    min-width: 12em !important;
    max-width: 12em !important;
}

.fw15 {
    width: 15em !important;
    min-width: 15em !important;
    max-width: 15em !important;
}

.fw18 {
    width: 18em !important;
    min-width: 18em !important;
    max-width: 18em !important;
}

.limitWidth {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-form {
    width: 40%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.select2-container .select2-choice {
    display: block;
    height: 32px;
    padding: 0 0 0 8px;
    overflow: hidden;
    position: relative;
    border: 1px solid #aaa;
    white-space: nowrap;
    line-height: 26px;
    color: #444;
    text-decoration: none;
    border-radius: 4px;
    background-clip: padding-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #eee), color-stop(0.5, #fff));
    background-image: -webkit-linear-gradient(center bottom, #eee 0%, #fff 50%);
    background-image: -moz-linear-gradient(center bottom, #eee 0%, #fff 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eeeeee', GradientType=0);
    background-image: linear-gradient(to top, #eee 0%, #fff 50%);
}

.search-btn {
    background-color: #5784c7;
    width: 40px;
    height: 30px;
    color: #ffffff;
}

.search-input {
    height: 30px;
    font-size: 14px;
    color: #cdcdcd;
    border: 1px solid #cdcdcd;
}

.list-table {
    font-size: 12px;
    color: #3e3e3e;

}

.detail-head {
    background: #f5f5f5 !important;
    height: 30px;
}

.pad-log {
    padding: 20px 0;
}

.language-pad {
    padding: 30px 100px !important;
}

.pagination-sm {
    background: #f1f2f7 !important;
    float: right !important;
}

.pagination-sm li {
}

.pagination-sm a {
    border: none !important;
    color: #3e3e3e !important;
    font-size: 14px !important;
    background: #fff !important;
    margin: 0 10px !important;
    padding: 5px 12px !important;
}

.pagination-sm .active a, .pagination-sm a:hover {
    background: #B2B2B2 !important;
    border-radius: 5px;
    color: #fff !important;
}

.pagination-sm > li > a, .pagination-sm > li > span {
    border-radius: 5px !important;
    border: 1px solid #B2B2B2 !important;
}

.pagination-next a, .pagination-prev a {
    font-family: "宋体";
    font-weight: 600;
}

.page {
    line-height: 76px;
    width: 60px;
    height: 30px;
    border-radius: 5px;
    border: none;
    margin-left: 10px;
}

.page-text {
    text-indent: 8px;
    height: 32px;
    width: 40px;
    /*vertical-align: middle;*/
    border: 1px solid #B2B2B2;
}

.page-go {
    color: #3e3e3e;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #B2B2B2;
    width: 44px;
    height: 32px;
    display: inline-block;
    line-height: 32px;
    text-align: center
}

.page-btn {
    background: #FF5001;
    line-height: 76px;
    color: #fff;
    padding: 6px 10px;
    cursor: pointer
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.font-14 {
    font-size: 14px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-30 {
    font-size: 30px;
}

.font-32 {
    font-size: 32px;
}

.font-45 {
    font-size: 45px;
}

.bgcolor-FC8674 {
    background-color: #FC8674;
}

.bgcolor-F3DADA {
    background-color: #F3DADA;
}

.bgcolor-FF6C5F {
    background-color: #FF6C5F;
}

.bgcolor-FF6C5F-fw {
    color: #FF6C5F;
    border: 1px solid #FF6C5F !important;
    outline: none;
    background: #fff;
}

.bgcolor-FF6C5F1 {
    color: #FF6C5F;
    border-bottom: 2px solid #FF6C5F;
    outline: none;
}

.dayTotal {
    width: 104px;
    height: 100%;
    float: left;
    outline: none;
    line-height: 38px;
    cursor: pointer;
    border: 1px solid #d1d1d1;
}

.day {
    border-radius: 4px 0 0 4px !important;
}

.quarter {
    border-radius: 0 4px 4px 0 !important;
}

.dayActive {

}

.bgcolor-f4f5fa {
    background-color: #f4f5fa;
}

.map-page {
    top: -30px;
    left: -220px;
    width: 111%;
    height: 800px;
}

.map-page-div {
    width: 111%;
    height: 1000px;
    left: -20px;
}

.map-page-btu {
    z-index: 2;
    position: absolute;
    top: 50px;
    left: 50px;
}

.map-page-img {
    width: 20px;
    height: 20px;
}

.picture-border {
    width: 100px;
    height: 100px;
    border: 1px dashed red;
}

.home-page-div {
    height: 800px;
    margin-left: 230px;
    margin-top: 20px;
}

.home-page-div .top-title {
    width: 100%;
    height: 150px;
}

.home-page-div .top-title .titleDiv {
    width: 30%;
    height: 100%;
    float: left;
    border: 1px solid #DEDEDE;
    background-image: url('../images/bg001.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.home-page-div .top-title .titleDiv .div0 {
    width: 40%;
    margin: 0 auto;
    margin-top: 30px;
}

.div-bar {
    width: 100%;
    height: 5px;
}

.height-500 {
    height: 500px;
}

.height-450 {
    height: 450px;
}

.height-125 {
    height: 125px;
}

.height-40 {
    height: 40px;
}

.height-5 {
    height: 5px;
}

.width-50 {
    width: 50px;
}

.width-20p {
    width: 20% !important;
}

.width-21p {
    width: 21%;
}

.width-80p {
    width: 80%;
}

.width-90p {
    width: 90%;
}

.width-100p {
    width: 100%;
}

.width-70p {
    width: 70% !important;
}

.width-50p {
    width: 50% !important;
}

.bgcolor-fff {
    background-color: #fff;
}

.platform-wechat-setting-tab {
    padding-left: 20px;
    font-size: 16px;
    margin-bottom: 50px;
}

.platform-wechat-setting-tab .span0 {
    margin-left: 20px;
    text-align: center;
    cursor: pointer;
    outline-style: none;
}

.salesAnalysis-btn2 {
    width: 60px;
    height: 35px;
    padding-top: 8px;
    border-radius: 5px;
    color: #fff;
    margin-left: 30px;
}

.text-center1 {
    text-align: center !important;
}

.text-left1 {
    text-align: left !important;
}

.mar-left-20 {
    margin-left: 20px;
}

.borderLine-top {
    border-top: 1px solid #EFEFEF;
    padding-top: 10px;
}

.btn-primary {
    background-color: #FE6760;
    border-color: #FE6760;
    color: #FFFFFF;
}

.btn-warning {
    background-color: #f8ac59;
    border-color: #f8ac59;
    color: #FFFFFF;
}

.row h3 {
    font-size: 16px;
    margin-top: 5px;
    font-weight: 600;
}

.store-wechat-setting-tab .hr0 {
    width: 160px;
    margin-left: 25px;
    height: 3px;
    background-color: red;
    margin-top: 10px;
}

.login_input {
    border-radius: 15px !important;
    height: 30px !important;
    color: white;
    background-color: rgba(255, 255, 255, 0.3) !important;
    background-color: #ffffff;
    font-size: 12px !important;
    filter: Alpha(opacity=30);
}

.themeList {
    float: left;
}

.displayChoose {
    margin-top: 28px;
    font-size: 12px;
}

.previewStyle {
    text-decoration: underline;
    cursor: pointer;
    outline: none;
}

.containerTheme {
    width: 248px;
    height: 440px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
}

.theme-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 1488px;
    margin: 0 !important;
    padding: 0 !important;
}

.slide, .imgTheme {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.slide {
    float: left;
    width: 248px;
}

.button-next, .button-prev {
    width: 20px;
    height: 40px;
    z-index: 9999;
    position: absolute;
    top: 45%;
    cursor: pointer;
    outline: none;
}

.button-prev {
    left: 157px;
}

.button-next {
    right: 157px;
}

.hide1 {
    display: none !important;
}

.clear-model {
    margin: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
}

.opacity-zero {
    opacity: 0 !important;
    -ms-filter: alpha(opacity=0) !important;
    filter: alpha(opacity=0) !important;
}

.logistice-0 {
    float: left;
    width: 148px;
    height: 60px;
    padding-top: 23px;
    list-style: none;
}

.logistice-1 {
    float: left;
    width: 148px;
    height: 60px;
    padding-top: 23px;
    list-style: none;
}

.checkbox-icon {
    width: 13px;
    height: 13px;
    margin-top: 10px;
}

.mar-tb-8 {
    margin: 8px 0;
}

.pad-lr-10 {
    padding: 0 10px !important;
}

.current-location {
    margin-bottom: 20px;
    /*position: fixed;*/
    /*height: 50px;*/
}

.sidebar2-shrink {
    position: fixed;
    top: 96px;
    left: 211px;
    z-index: 101;
    transition: all .5s ease;
}

.shidebar2-shrink-left {
    left: 220px;
    /*transition: all .2s ease;*/
}

.shidebar2-shrink-left2 {
    left: 120px !important;
}

.sidebar1-btn {
    outline: none;
    border: none;
    width: 21px;
    height: 12px;
}

.sidebar2-btn {
    border: none;
    width: 14px;
    height: 36px;
    outline: none;
}

.btn1-shrik1 {
    background: url("../images/icon/menu-shrink1.png") no-repeat center;
}

.btn1-shrik2 {
    background: url("../images/icon/menu-open1.png") no-repeat center;
}

.btn2-shrik1 {
    background: url("../images/icon/menu-shrink2.png") no-repeat center;
}

.btn2-shrik2 {
    background: url("../images/icon/open-menu.png") no-repeat center;
}

.bgc-f4f5fa {
    background-color: #F4F5FA;
}

.salesAnalysis-btn {
    width: 60px;
    height: 35px;
    padding-top: 8px;
    border-radius: 5px;
    color: #fff;
    margin-left: -110px;
}

.salesAnalysis-btn2 {
    width: 60px;
    height: 35px;
    padding-top: 8px;
    border-radius: 5px;
    color: #fff;
    margin-left: 30px;
}

.da-solid-border {
    border: 1px solid #ddd;
}

.da-solid-border-left {
    border-left: 1px solid #ddd;
}

.da-solid-border-right {
    border-right: 1px solid #ddd;
}

.bgcolor-FF6C5F {
    background-color: #ff6c5f;
}

.text-color {
    color: #fff;
}

.text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.statistical-sales-top {
    padding-left: 20px;
    padding-right: 20px;
}

.padding-left-right-0 {
    padding-left: 0px;
    padding-right: 0px;
}

.multimedia-group-topspan {
    float: left;
    width: 65px;
    height: 50px;
    font-size: 14px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
}

.multimedia-group-topdel {
    height: 32px;
    margin-left: 8px;
    background-color: #fff;
    border: 1px #ddd solid
}

.model-list-action {
    border-right: 1px solid #e9e9e9 !important;
    padding-right: 10px !important;
}

.model-detail-titel {
    padding: 20px 30px;
    background: #fff;
    border-bottom: 1px solid #ccc;
}

.model-label-selected {
    margin-left: 20px;
    border-bottom: 3px #FF5001 solid
}

.model-label-selected span {
    color: #FF5001;
}

.i-switch {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
    border-radius: 30px;
    background-color: #7acc3a;
    margin: 0;
}

.m-b-xs {
    margin-bottom: 5px;
}

.m-t-xs {
    margin-top: 5px;
}

.i-switch i:after {
    content: "";
    position: absolute;
    background-color: #fff;
    width: 18px;
    left: 0px;
    top: 1px;
    bottom: 1px;
    border-radius: 50%;
    -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, .25);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .25);
    -webkit-transition: margin-left .3s;
    transition: margin-left .3s;
}

.i-switch input:checked + i:before {
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 5px;
    border-width: 0;
    border-radius: 5px;
}

.i-switch input:checked + i:after {
    margin-left: 17px;
}

.i-switch input {
    position: absolute;
    opacity: 0;
}

.i-switch i:before {
    content: "";
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 30px;
    -webkit-transition: all .2s;
    transition: all .2s;
}

.outline {
    outline: none;
}

input[type="checkbox"] {
    outline: none !important;
}

.number-total {
    width: 200px;
    float: left;
    font-size: 12px;
    color: #929292;
    margin: 30px 0;
}

.number-li {
    height: 30px;
    line-height: 30px;
    outline: none;
    margin-bottom: 8px;
    cursor: pointer;
}

.numberActive {
    color: #f45454;
}

.page_title {
    height: 30px;
    line-height: 30px;
}

.text-right{
    text-align: right;
}
.current-location-menu{
    color: #FF5001 !important;
    border-bottom: 3px solid #FF5001 !important;
}
.current-location-menu-span{
    padding: 0 10px;
}
    
    /* 谷歌 */ 
.taskbill input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    } 

    /* 火狐 */ 
.taskbill input{
  -moz-appearance:textfield;
}

.taskbill .num-input {
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.taskbill-panel-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.taskbill-panel-client {
    width: 100%;
    height: 100%;
    position: absolute;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.taskbill-panel-form {
    position: absolute;
    overflow: hidden;
}

.taskbill .form-item {
    position: absolute;
    word-break: break-all;
    line-break: anywhere;
    overflow: hidden;
}

.taskbill .form-barcode-image {
    width: 100%;
    height: 100%;
    object-fit: fill;
    position: absolute;
}

.taskbill .form-projectImg {
    width: 100%;
    height: 100%;
    object-fit:contain;
    position: absolute;
}
.moduleNameBGC {
    background: #fff !important;
    margin-bottom: 10px !important;
}

/*-----------------------------------searchbar  start-----------------------------------------*/

.searchBar {
    /*width: 268px;*/
    /*max-width: 358px;*/
    width: auto;
    height: 30px;
    float: right;
}

.createBar {
    height: 30px;
    float: left;
    margin-left: 10px;
}

.createBar .pull-right {
    background: #FF5001 !important;
    border-color: #FF5001 !important;
    outline: none;
}

#searchQuery {
    font-size: 14px;
    border: 1px solid #D9D9D9 !important;
    width: 300px;
    height: 32px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: none;
    box-shadow: none;
    border-right: none !important;
    color: #FF5001 !important;
}

.btn-info {
    background: #FF5001 !important;
    border: 1px solid #FF5001;
}

.btn-info-blue {
    background: #2DA3FF !important;
    border: 1px solid #2DA3FF;
    color: #fff;
}

.btn-info-blue:hover {
    background: #2998ff !important;
    border: 1px solid #2998ff;
    color: #fff;
}

.btn-new {
    padding: 0 !important;
}

.btn-search {
    height: 32px;
    width: 70px;
    /*margin-left: -6px !important;*/
    z-index: 999;
    line-height: 16px;
    color: #fff;
    /*padding: 0!important;*/
    /*background: #ff6c5f url("../images/icon/q-icon.png") no-repeat center !important;*/
    background: #FF5001;
}

.btn-search:hover {
    color: #fff;
    z-index: 999;
}

.btn-searched {
    display: inline-block;
}

.btn-clear {
    /*margin: 0 30px;*/
    display: inline-block;
    vertical-align: middle;
    color: #fff;
}

.btn-clear button {
    border-radius: 4px !important;
    height: 32px;
    outline: none;
    background: #FF5001;
}

.btn-clear button:hover {
    color: #fff;
}

.choose-list, .choose-li {
    outline: none;
    padding: 16px;
    background: url("../images/icon/choose_none.svg") no-repeat center;
}

.choose-sel-all, .choose-sel {
    outline: none;
    padding: 16px;
    background: url("../images/icon/choose_sel.svg") no-repeat center;
}

.choose-enabled {
    outline: none;
    padding: 12px 25px;
    background: url("../images/icon/open.svg") no-repeat center;
}

.choose-unabled {
    outline: none;
    padding: 12px 25px;
    background: url("../images/icon/close.svg") no-repeat center;
}

.choose-li-open {
    outline: none;
    padding: 16px;
    background: url("../images/icon/li-open.svg") no-repeat center;
}

.choose-li-close {
    outline: none;
    padding: 16px;
    background: url("../images/icon/li-close.svg") no-repeat center;
}

.table-title {
    height: 50px;
    line-height: 47px;
    background: #fff;
    border-radius: 5px;
    padding-left: 30px;
}

.input-upload {
    width: 104px;
    height: 104px;
    opacity: 0;
    z-index: 100;
    position: relative;
    cursor: pointer;
}

.pic-upload {
    position: absolute;
    top: 0;
    z-index: 10;
}

.tip-text {
    color: rgba(35, 35, 35, 0.4);
    font-size: 12px;
}

.input-number::-webkit-outer-spin-button,
.input-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.input-number {
    margin: 0 auto;
    text-align: center;
    padding: 0;
    -moz-appearance: textfield;
}

.current-title {
    color: #000;
    font-weight: 600;
    font-size: 16px;
}

.current-nav {
    margin-right: 40px;
    display: inline-block;
    border-bottom: 3px solid #fff;
    color: #232323;
    padding: 0 20px;
    outline: none;
    cursor: pointer;
}

.current-nav-active {
    color: #FF5001 !important;
    border-bottom: 3px solid #FF5001 !important;
}

.store-line {
    min-height: 62px;
    border-bottom: 1px dotted #e9e9e9;
    line-height: 62px;
    overflow: hidden;
}

.store-line label {
    height: 62px;
}

.smallapp-store {
    /*line-height: 62px;*/
}

.height-62 {
    height: 62px;
}

.smallapp-store div {
    padding: 0 10px;
    float: left;
    border-radius: 3px;
    outline: none;
    height: 22px;
    margin: 20px 11px;
    line-height: 22px;
}

.smallapp-store span {
    font-size: 14px;
}

.arrow-show {
    outline: none;
}

.arrow-show span {
    color: #1890ff;
}

.arrow-down {
    padding: 8px;
    background: url("../images/icon/arrow_down.svg") no-repeat center;
}

.arrow-up {
    padding: 8px;
    background: url("../images/icon/arrow_up.svg") no-repeat center;
}

.choose-file {
    position: absolute;
    z-index: 10;
    top: 0
}

/*-----------------------------------searchbar  end-----------------------------------------*/

/*----------------------------------------table  start---------------------------------------*/
.table {
    margin: 0 !important;
}

.table-responsive {
    background: #fff !important;
    text-align: center;
}

.table-thead {
    height: 35px;
    line-height: 35px;
}

.table-thead tr {
    background: #e5e5e5 !important;
}

.table-thead th {
    text-align: center;
    padding: 18px 0 !important;
    border-bottom-width: 1px !important;
}

.table-thead span {
    color: #3e3e3e !important;
    font-weight: normal;
}

.table-tbody td {
    color: #3e3e3e !important;
    text-align: center;
    font-size: 12px;
    padding: 9px 0 !important;
    border-right: 1px solid #ddd;
}

.html-text-align {
    display: table-cell;
    vertical-align: middle;
}

.table-tbody .text-right span {
    display: inline-block;
    width: 23px;
    height: 23px;
}

.text-right button {
    padding: 0 6px;
    outline: none;
}

.btn-check {
    color: #f60;
    background: url("../images/icon/show1.png") no-repeat center;
}

.btn1:hover .btn-check {
    background: url("../images/icon/show.png") no-repeat center;
}

.btn-editAmount {
    background: url("../images/icon/editAmount1.png") no-repeat center;
}

.btn-editAmount:hover {
    background: url("../images/icon/editAmount.png") no-repeat center;
}

.btn-edit {
    background: url("../images/icon/edit1.png") no-repeat center;
}

.btn2:hover .btn-edit {
    background: url("../images/icon/edit.png") no-repeat center;
}

.btn-del {
    background: url("../images/icon/del1.png") no-repeat center;
}

.btn-del:hover {
    background: url("../images/icon/del.png") no-repeat center;
}

.btn-ups {
    background: url("../images/icon/ups1.png") no-repeat center;
}

.btn-ups:hover {
    background: url("../images/icon/ups.png") no-repeat center;
}

.btn-sale {
    background: url("../images/icon/sale.png") no-repeat center;
}

.btn-sale:hover {
    background: url("../images/icon/sale1.png") no-repeat center;
}

.btn-delivery {
    background: url("../images/icon/delivery1.png") no-repeat center;
}

.btn-delivery:hover {
    background: url("../images/icon/delivery.png") no-repeat center;
}

.btn-down {
    background: url("../images/icon/down1.png") no-repeat center;
}

.btn-down:hover {
    background: url("../images/icon/down.png") no-repeat center;
}

.btn-justify {
    background: url("../images/icon/reJustify1.png") no-repeat center;
}

.btn-justify:hover {
    background: url("../images/icon/reJustify.png") no-repeat center;
}

.checkbox-0 {
    background: url("../images/icon/checkbox0.png") no-repeat center;
}

.checkbox-1 {
    background: url("../images/icon/checkbox1.png") no-repeat center;
}

.btn-simple {
    background: url("../images/icon/simple.png") no-repeat center;
}

.btn-simple:hover {
    background: url("../images/icon/simple2.png") no-repeat center;
}

.btn-resetPassword {
    background: url("../images/icon/resetPassword1.png") no-repeat center;
}

.btn-resetPassword:hover {
    background: url("../images/icon/resetPassword.png") no-repeat center;
}

.btn-clean {
    background: url("../images/icon/btn_clean.png") no-repeat center;
}

.btn-clean:hover {
    background: url("../images/icon/btn_clean_hover.png") no-repeat center;
}

.btn-submit {
    background: url("../images/icon/submit.png") no-repeat center;
}

.btn-submit:hover {
    background: url("../images/icon/submit2.png") no-repeat center;
}

.btn-approve {
    background: url("../images/icon/approve.png") no-repeat center;
}

.btn-approve:hover {
    background: url("../images/icon/approve2.png") no-repeat center;
}

.btn-reject {
    background: url("../images/icon/reject.png") no-repeat center;
}

.btn-reject:hover {
    background: url("../images/icon/reject2.png") no-repeat center;
}

.btn-scyn {
    background: url("../images/icon/refresh1.png") no-repeat center;
}

.btn-scyn:hover {
    background: url("../images/icon/refresh.png") no-repeat center;
}

.btn-close {
    background: url("../images/icon/store_close.svg") no-repeat center;
}

.btn-close:hover {
    background: url("../images/icon/store_close2.svg") no-repeat center;
}

.btn-custom {
    background: url("../images/icon/store_custom.svg") no-repeat center;
}

.btn-custom:hover {
    background: url("../images/icon/store_custom2.svg") no-repeat center;
}

.btn-bokeh {
    background: url("../images/icon/store_bokeh.svg") no-repeat center;
}

.btn-bokeh:hover {
    background: url("../images/icon/store_bokeh2.svg") no-repeat center;
}

.btn-link {
    background: url("../images/icon/link.svg") no-repeat center;
}

.btn-link:hover {
    background: url("../images/icon/link-selected.svg") no-repeat center;
}

.btn-load {
    background: url("../images/icon/load.svg") no-repeat center;
}

.btn-load:hover {
    background: url("../images/icon/reload.svg") no-repeat center;
}

.btn-pay {
    background: url("../images/icon/pay.svg") no-repeat center;
}

.btn-pay:hover {
    background: url("../images/icon/pay2.svg") no-repeat center;
}

.btn-up-type {
    background: url("../images/icon/up_type1.png") no-repeat center;
}

.btn-up-type:hover {
    background: url("../images/icon/up_type.png") no-repeat center;
}

.btn-sub:hover .hover-box {
    display: block !important;
}

.btn-sub {
    background: none;
    padding: 0;
    border: none;
    position: relative;
}

.btn-text {
    min-height: 26px;
    color: #1890ff;
    border: none;
    background: none;
}

.hover-box {
    position: absolute;
    top: -26px;
    left: 0;
    width: 40px !important;
    height: 24px !important;
    display: none;
    font-size: 9px;
    color: #3e3e3e;
    padding: 4px;
    background: #fff url("../images/icon/hoverbox.png") no-repeat center;
}

.hover-box2 {
    position: absolute;
    top: -26px;
    left: -15px;
    width: 65px !important;
    height: 26px !important;
    display: none;
    font-size: 9px;
    color: #3e3e3e;
    padding: 4px;
    background: #fff url(../images/icon/hoverbox55.png) no-repeat center;
}

.btn-sub2:hover .hover-box2 {
    display: block !important;
}

/***********************��ֱ���е����**************************/
.btn-sub2:hover .hover-box2 {
    display: block !important;
}

.btn-sub2 {
    background: none;
    padding: 0;
    border: none;
    position: relative;
}

.hover-box2 {
    position: absolute;
    top: -26px;
    left: 0;
    width: 40px !important;
    height: 24px !important;
    display: none;
    font-size: 9px;
    color: #3e3e3e;
    padding: 4px;
    background: #fff url("../images/icon/hoverbox.png") no-repeat center;
}

.hover-span {
    position: absolute;
    width: 40px !important;
    height: 24px !important;
    margin-left: -18px;
}

/*----------------------------------------table  end---------------------------------------*/

/*-------------------------------------------pages  start-----------------------------------*/
.text-center {
    background: #fff !important;
}

/*-------------------------------------------pages  end-----------------------------------*/
.alerts, .alerts-all {
    background: #fff !important;
}

.border-bottom {
    border-bottom: 1px solid #ddd !important;
}

.line-height-100 {
    line-height: 100px;
}

.btn-new-padding {
    padding: 0px 12px;
    outline: none;
}

.btn-batch {
    background: #fff !important;
    border: 1px solid #d9d9d9;
    color: #979797;
    outline: none;
}

.btn-batch:hover {
    color: #979797 !important;
}

.area-wrap {
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    min-height: 150px;
    float: left;

}

.area-head {
    background: #f5f5f5;
    height: 30px;
    line-height: 30px;
    /*text-align: center;*/
    border-bottom: 1px solid #e3e3e3;
}

.help-block {
    color: red !important;
}

.command-nav {
    width: 80px;
    border: none;
    background: #fff;
    border: 1px solid #d9d9d9;
    color: #979797;
    height: 32px;
    line-height: 30px;
    outline: none;
}

.command-nav-l {
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.command-nav-c {
    border-right: none;
    border-left: none;
}

.command-nav-r {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.nav-active {
    color: #FF5001;
    border-color: #FF5001;
    border-right: 1px solid #FF5001;
    border-left: 1px solid #FF5001;
}

.modal-content {
    width: 690px;
    margin: 250px auto;
}

.listCreate {
    border: 1px solid #bbb;
    width: 700px;
    background: #fff !important;
}

.modal-create {
    background: #f5f5f5 !important;
}

.modal-create h4 {
    color: #7b7b7b;
    font-size: 14px;
}

.modal-body .panel-body, .modal-body {
    padding-bottom: 0 !important;
}

/*.td-pla1 input{padding-right: 100px;}*/
.modal-body, .modal-footer {
    background: #fff !important;
}

.modal-body label {
    font-size: 14px;
    color: #3e3e3e;
    font-weight: normal;
    padding-right: 0 !important;
    /*white-space: pre;*/
}

/*.modal-body input, .modal-body select {*/
/*width: 170px !important;*/
/*height: 22px !important;*/
/*border: 1px solid #bbb;*/
/*border-radius: 0;*/
/*box-shadow: none;*/
/*padding-top: 0;*/
/*padding-bottom: 0;*/
/*outline: none !important;*/
/*}*/

/*.form-group{margin:0 0 20px;}*/
.modal-body option {
    font-size: 12px;
}

.form-group {

}

.text-pad {
    padding-bottom: 15px;
}

.subitemBGC {
    padding-left: 30px;
}

.checkbox-outline {
    width: 30px;
    height: 30px;
}

.modal-footer {
    /*border-top: 1px dashed #bbb;*/
    margin: 0 16px;
    padding: 30px 0 30px;
}

.btn-cancel, .btn-save {
    /*height: 26px;*/
    padding: 4px 12px!important;
}

.btn-cancel {
    background: #fff;
    border-color: #ddd;
}

.btn-cancel span {
    color: #3e3e3e;
    font-size: 12px;
}

.btn-cancel:hover span {
    color: #131a1d;
}

.btn-save {
    background: #FF5001!important;
    border-color: #FF5001!important;
    color: #fff;
}

.btn-save:hover {
    background: #FF5001!important;
    border-color: #FF5001!important;
}

.subitemBGC {
    background: #fff;
    border: none;
}

.table-titles {
    padding-left: 0;
}

.table-fw {
    border: 1px solid #bbb;
    width: 674px;
}

.table-head {
    background: #f5f5f5 !important;
    height: 35px !important;
    line-height: 35px;
    border-bottom: 1px solid #bbb;
}

.table-body tr {
    margin: 2px !important;
}

.table-fw th {
    font-size: 14px;
    color: #3e3e3e !important;
    font-weight: normal;
    text-align: center;
}

.table-fw td {
    height: 40px !important;
}

.table-fw .td-del span {
    margin-top: 5px;
    display: inline-block;
}

.table-fw .th-val span {
    display: inline-block;
    width: 170px !important;
    height: 35px;
}

.table-fw tbody td {
    text-align: center;
}
.table-fw tbody td input {
    width: 80%;
    display: inline-block;
}

.add {
    height: 16px;
    margin-top: 4px;
    outline: none;
}

.btn-add {
    background: url("../images/add-icon.png") no-repeat center 4px;
    display: inline-block;
    width: 14px;
    height: 18px;
}

.text-add {
    color: #319edd;
    margin-left: 4px;
    font-size: 14px;
}

/*------------------------------register--------------------------------*/
.row-registers {
    margin: 0;
    border-radius: 0 !important;
    background: #fff;
    width: 734px;
}

.registers {
    height: 40px;
    line-height: 40px;
    background: #f5f5f5;
    width: 734px;
}

.register-title {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-weight: normal;
    padding-left: 20px;
    margin: 0;
    color: #3e3e3e;
    border-bottom: 1px solid #e5e5e5;
}

.register-body {
}

.register-body label {
    color: #3e3e3e;
    font-weight: normal;
}

.footer-registers {
    margin: 0;
    padding: 20px 15px 30px;
}

.close {
    margin: 4px 10px 0 0;
    outline: none;
}
.article-view{
    /*border-bottom: 1px solid #ff6c5f;*/
    color: #FF5001;
    cursor: pointer;
}
.article-view:hover{
    /*border-bottom: 1px solid #FF583B;*/
    color: #FF583B;
}

.modal-content {
    width: 690px;
    margin: 250px auto;
}

.listDetail {
    border: 1px solid #bbb;
    width: 700px;
    padding: 0 !important;
    background: #fff !important;
}

.modal-detail {
    background: #fff !important;
    height: 40px;
    line-height: 40px;
    padding-left: 30px;
    color: #ff6c5f;
    border-bottom: 1px solid rgb(229, 229, 229);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.modal-detail span, .modal-detail h4 {
    color: #232323;
    font-size: 16px;
    /*line-height: 40px;*/
    margin: 18px 0;
}

/*.modal-body input, .modal-body select {*/
/*width: 170px !important;*/
/*height: 22px !important;*/
/*border: 1px solid #bbb;*/
/*border-radius: 0;*/
/*box-shadow: none;*/
/*padding-top: 0;*/
/*padding-bottom: 0;*/
/*outline: none !important;*/
/*}*/

/*.form-group{margin:0 0 20px;}*/
.modal-body option {
    font-size: 12px;
}

.form-group {

}

.text-pad {
    padding-bottom: 15px;
}

.subitemBGC {
    padding-left: 30px;
}

.checkbox-outline {
    width: 30px;
    height: 30px;
}

.yzmStatus {
    position: absolute;
    line-height: 34px;
    top: 22px;
    right: 0;
}

.yzmStatus i:nth-child(1) {
    background: url("../../content/images/shape-yes.png") no-repeat 0;
    padding: 13px;
    margin-left: 20px;
}

.yzmStatus i:nth-child(2) {
    background: url("../../content/images/error_16px.png") no-repeat 0;
    padding: 16px;
    margin-left: 20px;
    display: none;
}

.btn-cancel, .btn-save {
    /*height: 26px;*/
    padding: 4px 12px !important;
}

.btn-cancel {
    background: #fff;
    border-color: #ddd;
}

.btn-cancel span {
    color: #3e3e3e;
    font-size: 12px;
}

.btn-cancel:hover span {
    color: #131a1d;
}

.btn-save {
    background: #FF5001;
    border-color: #FF5001;
}

.btn-save:hover {
    background: #ff6c5f;
    border-color: #ff6c5f;
}

.subitemBGC {
    background: #fff;
    border: none;
}

.table-titles {
    padding-left: 0;
}

.table-fw {
    width: 674px;
}

.table-body td {
    height: 80px !important;
}

.table-head {
    background: #f5f5f5 !important;
    height: 35px !important;
    line-height: 35px;
    border-bottom: 1px solid #bbb;
}

.table-body tr {
    margin: 2px !important;
}

.table-fw th {
    font-size: 14px;
    color: #3e3e3e !important;
    font-weight: normal;
    text-align: center;
}

.table-fw td {
    height: 40px !important;
}

.table-fw .td-del span {
    margin-top: 5px;
    display: inline-block;
    min-width: 50px;
    min-height: 24px;
}

.table-fw .th-val span {
    display: inline-block;
    width: 170px !important;
    height: 35px;
}

.table-fw tbody td {
    text-align: center;
}

.add {
    height: 16px;
    margin-top: 4px;
    outline: none;
}

.btn-add {
    background: url("../images/add-icon.png") no-repeat center 4px;
    display: inline-block;
    width: 14px;
    height: 18px;
}

.btn-delete {
    background: url('../images/delete-icon.png') no-repeat center;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-top: 6px;
}

.red-btn-add {
    background: url("../images/icon/add.svg") no-repeat center;
    display: inline-block;
    width: 20px;
    height: 20px;
    float: left;
}

.text-add {
    color: #319edd;
    margin-left: 4px;
    font-size: 14px;
}

/*------------------------------register--------------------------------*/
.row-registers {
    margin: 0;
    border-radius: 0 !important;
    background: #fff;
    width: 734px;
}

.registers {
    height: 40px;
    line-height: 40px;
    background: #f5f5f5;
    width: 734px;
}

.register-title {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-weight: normal;
    padding-left: 20px;
    margin: 0;
    color: #3e3e3e;
    border-bottom: 1px solid #e5e5e5;
}

.register-body {
}

.register-body label {
    color: #3e3e3e;
    font-weight: normal;
}

.footer-registers {
    margin: 0;
    padding: 20px 15px 30px;
}

.close {
    margin: 0 10px 0 0;
    outline: none;
}

.sidespan {
    padding-left: 14px;
}

.view-article {
    position: relative;
}

.pro-view, .pro-view2 {
    outline: none;
    float: left;
    color: #ff6c5f !important;
}

.pro-view {
    position: absolute;
    bottom: 10px;
    right: 0;
}

.pro-view2 {
    min-height: 44px;
    line-height: 44px;
}

.pro-view2 span, .pro-view span {
    border-bottom: 1px solid #ff6c5f;
}

.pro-view:hover, .pro-view2:hover {
    cursor: pointer;
    color: #FF4C3D !important;
}

.position-relative {
    position: relative;
}

.hide1 {
    display: none !important;
}

.opacity0 {
    background-color: #ffffff00 !important;
    border: 1px solid rgba(0, 0, 0, 0) !important;
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0) !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0) !important;
}

.leftBtn, .rightBtn {
    position: absolute;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    top: 45%;
    z-index: 999;
}

.rightBtn {
    right: -20%;
}

.leftBtn {
    display: none;
    left: -20%;
}

.barcode {
    position: absolute;
}

.barcode img {
    width: 100%;
}

.save-page {
    position: fixed;
    z-index: 99999;
    top: 50%;
    left: 50%;
    width: 130px;
    /*display: none;*/
}

.save-page div {
    position: absolute;
    top: 42%;
    left: 42%;
}

.save-page img {
    width: 20px;
    margin: -1px 4px 0 0;
}

.save-page span {
    color: #fff;
}

/***************预览页面*********************/
.templateView-carousel {
    margin: 0 auto;
    position: relative;
}

.box-shadows {
    box-shadow: 0 2px 5px rgba(187, 187, 187, 0.6);
}

.slide-content {
    /*background-color: #fff;*/
    position: relative;
}

.diy-box {
    position: absolute;
}

.diy-box input, .diy-box textarea, .diy-box .choose-img-box {
    border: none !important;
    background-color: transparent;
    outline: none;
    width: 100%;
    height: 100%;
    padding: 0;
}

.diy-box input[type="text"], .diy-box .choose-img-box {
    line-height: 12px;
    font-family: 'xujinglei', sans-serif;
    font-weight: bold;
    position: relative;
}

.diy-box input:active, .diy-box textarea:active {
    outline: none;
}

.diy-box input[type='file'], .diy-box .choose-img-box {
    opacity: 0;
    -ms-filter: alpha(opacity=0);
    filter: alpha(opacity=0);
    position: absolute;
    top: 0;
    left: 0;
}

.relative-box {
    width: 100%;
    height: 100%;
    position: relative;
}

.indexSet-800 {
    z-index: 800;
}

.center-rotation {
    transform-origin: center;
}

.leftbo-rotation {
    transform-origin: left bottom !important;
}

/**
    素材
    **/
.sticker-edit-box {
    position: absolute;
    outline: none;
    /*transform-origin: center*/
}

/**
    文字
    **/
.text-box {
    position: absolute;
    /*padding: 0 12px;*/
    /*min-width: 100px;*/
}

.text-content {
    position: absolute;
    top: 0;
    left: 0;
}

.text-box .selected {
    border: 1px solid #F8E71C;
}

.selecteds {
    border: 1px solid #F8E71C;
}

.text-box p {
    width: 100%;
    height: 100%;
    word-break: break-all;
    margin: 0;
}

.sticker-edit-box span {
    width: 20px;
    height: 20px;
    position: absolute;
}

.text-box img {
    width: 100%;
}

.img-art {
    position: absolute;
}

pre {
    display: block;
    padding: 0;
    margin: 0;
    word-wrap: unset;
    background-color: inherit;
    border: none;
    border-radius: 0;
    overflow: unset;
}

.c-xml-page-textboxe-text {
    display: inline-block;
    margin: 0;
    padding: 0;
    word-break: break-all;
    width: 100%;
    transform-origin: left top;
}

.c-xml-page-textboxe-span {
    display: inline-block;
    width: 100%;
    /*white-space: pre-wrap;*/
    white-space: break-spaces;
    word-break: break-word;
}

.c-xml-page-textboxe {
    position: absolute;
}

.half-width {
    width: 50% !important;
}

.half-margin {
    margin-left: 50% !important;
}

.quarter-margin {
    margin-left: 25% !important;
}

.hide1 {
    display: none !important;
}

.origin-image {
    transition: opacity .15s linear;
    min-width: 100vw;
    min-height: 103vh;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 1100;
    top: 0;
    left: 0;
    text-align: center;
    display: table;
    overflow: auto;
}

.origin-wrap {
    margin: auto;
    vertical-align: middle;
    text-align: center;
    width: auto;
    height: auto;
    display: table-cell;
}

.origin-wrap img {
    max-width: 70vw;
    max-height: 88vh;
}

.width-maxd {
    width: 100%;
    height: auto;
}

.height-maxd {
    height: 100%;
    width: auto;
}

.clear-model {
    margin: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
}

.bb-bookblock {
	/*width: 80vw;*/
	/*height: 150px;*/
	margin: -32px auto;
	position: relative;
	z-index: 991;
	-webkit-perspective: 1300px;
	-moz-perspective: 1300px;
	perspective: 1300px;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
    padding: 0;
}
ul li{
    list-style: none!important;
}
.view-tip1{
    position: absolute;
    left:16vw;
    top:10vw;
    background: #fff;
    /*margin: 50px 0 0 10px;*/
}
.view-tip1 img{
    width: 80px;
}

.bb-page {
	position: absolute;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transition-property: -webkit-transform;
	-moz-transition-property: -moz-transform;
	transition-property: transform;
}

.bb-vertical .bb-page {
	width: 50%;
	height: 100%;
	left: 50%;
	-webkit-transform-origin: left center;
	-moz-transform-origin: left center;
	transform-origin: left center;
}

.bb-horizontal .bb-page {
	width: 100%;
	height: 50%;
	top: 50%;
	-webkit-transform-origin: center top;
	-moz-transform-origin: center top;
	transform-origin: center top;
}

.bb-page > div,
.bb-outer,
.bb-content,
.bb-inner {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background: #fff;
}
.bb-inner img{
    width: 100%;
}

.bb-vertical .bb-content {
	width: 200%;
}

.bb-horizontal .bb-content {
	height: 200%;
}

.bb-page > div {
	width: 100%;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.bb-page > div:not(:only-child) {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
}

.bb-vertical .bb-back {
	-webkit-transform: rotateY(-180deg);
	-moz-transform: rotateY(-180deg);
	transform: rotateY(-180deg);
}

.bb-horizontal .bb-back {
	-webkit-transform: rotateX(-180deg);
	-moz-transform: rotateX(-180deg);
	transform: rotateX(-180deg);
}

.bb-outer {
	width: 100%;
	overflow: hidden;
	z-index: 999;
}

.bb-overlay,
.bb-flipoverlay {
	background-color: rgba(0, 0, 0, 0.2);

	position: absolute;
	/*top: 0;*/
	/*left: 0;*/
	width: 100%;
	height: 100%;
	opacity: 0;
}

.bb-flipoverlay {
	background-color: rgba(0, 0, 0, 0.2);
}

/* */

.bb-bookblock.bb-vertical > div.bb-page:first-child,
.bb-bookblock.bb-vertical > div.bb-page:first-child .bb-back {
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
	transform: rotateY(180deg);
}

.bb-bookblock.bb-horizontal > div.bb-page:first-child,
.bb-bookblock.bb-horizontal > div.bb-page:first-child .bb-back {
	-webkit-transform: rotateX(180deg);
	-moz-transform: rotateX(180deg);
	transform: rotateX(180deg);
}

/* Content display */
.bb-vertical .bb-front .bb-content {
	left: -100%;
}

.bb-horizontal .bb-front .bb-content {
	top: -100%;
}

/* Flipping classes */
.bb-vertical .bb-flip-next,
.bb-vertical .bb-flip-initial {
	-webkit-transform: rotateY(-180deg);
	-moz-transform: rotateY(-180deg);
	transform: rotateY(-180deg);
}

.bb-vertical .bb-flip-prev {
	-webkit-transform: rotateY(0deg);
	-moz-transform: rotateY(0deg);
	transform: rotateY(0deg);
}

.bb-horizontal .bb-flip-next,
.bb-horizontal .bb-flip-initial {
	-webkit-transform: rotateX(180deg);
	-moz-transform: rotateX(180deg);
	transform: rotateX(180deg);
}

.bb-horizontal .bb-flip-prev {
	-webkit-transform: rotateX(0deg);
	-moz-transform: rotateX(0deg);
	transform: rotateX(0deg);
}

.bb-vertical .bb-flip-next-end {
	-webkit-transform: rotateY(-15deg);
	-moz-transform: rotateY(-15deg);
	transform: rotateY(-15deg);
}

.bb-vertical .bb-flip-prev-end {
	-webkit-transform: rotateY(-165deg);
	-moz-transform: rotateY(-165deg);
	transform: rotateY(-165deg);
}

.bb-horizontal .bb-flip-next-end {
	-webkit-transform: rotateX(15deg);
	-moz-transform: rotateX(15deg);
	transform: rotateX(15deg);
}

.bb-horizontal .bb-flip-prev-end {
	-webkit-transform: rotateX(165deg);
	-moz-transform: rotateX(165deg);
	transform: rotateX(165deg);
}

.bb-item {
    /*z-index: 996;*/
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
    overflow: hidden;
	display: none;
}
.bb-white{
    background: #fff;
}
.bb-item img{
    width: 100%;
}

/* No JS */
.no-js .bb-bookblock,
.no-js ul.bb-custom-grid li {
	width: auto;
	height: auto;
}

.no-js .bb-item {
	display: block;
	position: relative;
}



.bb-custom-wrapper {
    position: relative;
    text-align: center;
    /*width: 80vw;*/
}

.bb-bg{
    /*margin-top: 30vh;*/
    /*background-image: url("../images/view_bg.png");*/
    min-height: 100%;
    background-size: cover;
    background-attachment: fixed;
}
.opacity-zero{
    opacity: 0!important;
    -ms-filter: alpha(opacity=0)!important;
    filter:alpha(opacity=0)!important;
}
.bb-disabled{
    pointer-events: none;
    cursor: default;
}
.bb-custom-side {
    width: 50%;
    float: left;
    height: 100%;
    overflow: hidden;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.bb-custom-side img {
    /*width: 100%;*/
}

.bb-custom-all .bb-bookblock {
    width: 100%;
    height: 100%;
    -webkit-perspective: 2000px;
    -moz-perspective: 2000px;
    perspective: 2000px;
}

.bb-item{
    box-shadow: 0 12px 20px -10px rgba(81, 64, 49, 0.6)!important;
}
.lar-noShadow{
    box-shadow: 0 12px 6px -10px rgba(81, 64, 49, 0.6)!important;
}
.uad-noShadow{
    box-shadow: 0 12px 20px -30px rgba(81, 64, 49, 0.6)!important;
}
/*.bb-bookblock li:nth-child(1){*/
    /*box-shadow: none;*/
/*}*/
.bb-custom-wrapper h3 {
    font-size: 1.4em;
    font-weight: 300;
    margin: 0.4em 0 1em;
}

.bb-custom-wrapper nav {
    width: 100%;
    height: 30px;
    margin: 1em auto 0;
    position: relative;
    z-index: 0;
    text-align: center;
}

.bb-custom-wrapper nav a {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 2px;
    background: #72b890;
    color: #fff;
    font-size: 0;
    margin: 2px;
}

.bb-custom-wrapper nav a:hover {
    opacity: 0.6;
    -ms-filter: alpha(opacity=60);
    filter:alpha(opacity=60)
}

.bb-custom-icon:before {
    font-family: 'arrows';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 20px;
    line-height: 30px;
    display: block;
    -webkit-font-smoothing: antialiased;
}

.bb-custom-icon-first:before,
.bb-custom-icon-last:before {
    content: "\e002";
}

.bb-custom-icon-arrow-left:before,
.bb-custom-icon-arrow-right:before {
    content: "\e003";
}

.bb-custom-icon-arrow-left:before,
.bb-custom-icon-first:before {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

/* No JS */
.no-js .bb-custom-wrapper {
    height: auto;
}

.no-js .bb-custom-content {
    height: 470px;
}

#center {

    LEFT: 50%;
    POSITION: absolute;
    TOP: 50%

}

#dhtmlbook {

    background: #000;
    left: -210px;
    visibility: hidden;
    width: 420px;
    position: relative;
    top: -160px;
    height: 320px

}

#txtbox {

    font-size: 0.8em;
    width: 410px;
    color: #aba193;
    font-family: verdana;
    position: absolute;
    top: 320px;
    text-align: center

}

.page1 {

    overflow: hidden;
    border-left: #000000 1px solid;
    width: 50%;
    cursor: pointer;
    position: absolute;
    height: 100%

}

.right {

    border-right: #000000 1px solid;
    left: 50%

}

.turn {

    background: #000000

}

.img {

    width: 200%;
    position: absolute;
    height: 100%

}

.book {
    margin: 20px auto 10px;
    box-sizing: border-box;
    height: 30px;
    width: 41px;
    background: #fff;
    border: 1px solid #ffa91b;
    border-radius: 4px;
    -webkit-perspective: 300px;
    perspective: 300px;
    position: relative;
}
.book__page {
    position: absolute;
    height: 108%;
    width: 59%;
    background: #fff;
    top: -1px;
    right: -1px;
    border: inherit;
    border-radius: inherit;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
    -webkit-animation: page-flip 5s linear infinite;
    animation: page-flip 5s linear infinite;
}
.book__page__fill {
    background: #00a3b6;
    width: 80%;
    border: inherit;
    margin: 0 auto;
    border-width: 1px;
    border-bottom: none;
    border-radius: inherit;
    box-sizing: border-box;
    margin-top: 4px;
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-animation: fill-1 5s linear forwards infinite;
    animation: fill-1 5s linear forwards infinite;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.book__page__fill:nth-child(2) {
    -webkit-animation-name: fill-2;
    animation-name: fill-2;
}
.book__page__fill:nth-child(3) {
    -webkit-animation-name: fill-3;
    animation-name: fill-3;
}
.book__page__fill:nth-child(4) {
    -webkit-animation-name: fill-4;
    animation-name: fill-4;
}
.book__page__fill:nth-child(5) {
    -webkit-animation-name: fill-5;
    animation-name: fill-5;
}

@-webkit-keyframes page-flip {
    50% {
        -webkit-transform: rotateY(0) translateX(0);
        transform: rotateY(0) translateX(0);
    }
    80%, 100% {
        -webkit-transform: rotateY(-180deg) translateX(-5px);
        transform: rotateY(-180deg) translateX(-5px);
    }
}

@keyframes page-flip {
    50% {
        -webkit-transform: rotateY(0) translateX(0);
        transform: rotateY(0) translateX(0);
    }
    80%, 100% {
        -webkit-transform: rotateY(-180deg) translateX(-5px);
        transform: rotateY(-180deg) translateX(-5px);
    }
}
@-webkit-keyframes fill-1 {
    10%, 100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}
@keyframes fill-1 {
    10%, 100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}
@-webkit-keyframes fill-2 {
    10% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }
    20%, 100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}
@keyframes fill-2 {
    10% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }
    20%, 100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}
@-webkit-keyframes fill-3 {
    20% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }
    30%, 100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}
@keyframes fill-3 {
    20% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }
    30%, 100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}
@-webkit-keyframes fill-4 {
    30% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }
    40%, 100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}
@keyframes fill-4 {
    30% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }
    40%, 100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}
@-webkit-keyframes fill-5 {
    40% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }
    50%, 100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}
@keyframes fill-5 {
    40% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }
    50%, 100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

.custom-grid {
    background-color: #fff;
    background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee), linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee);
    background-size: 16px 16px;
    background-position: 0 0, 8px 8px;
    width: 100%;
    height: 100%;
}

.loading-page2 {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    z-index: 9;
}

.threeView-body{
    width: 100%;
    height: 100%;
    position: relative;
}
.threeView{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.threeLoading{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80px;
    height: 80px;
    z-index: 1;
    margin: -40px 0 0 -40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.threeError{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 300px;
    height: 80px;
    z-index: 1;
    margin: -40px 0 0 -150px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #999;
}
.threeHide{
    position: absolute;
    right: 0;
    top: 0;
    height: 35px;
    width: 35px;
    cursor: pointer;
    z-index: 99;
    text-align: center;
    line-height: 35px;
    background: url("../images/icon/icon-delete.png") no-repeat center;
    background-size: 18px;
}





.addressBtn{
    display: inline-block;
    height: 35px;
    line-height: 35px;
    color: #FF5001;
    cursor: pointer;
    text-decoration: underline;
}
.c-address-view{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.c-address-view-li{
    width: 35%;
    border-right: 1px solid #ddd;
}
.c-address-view-li-head{
    width: 100%;
    height: 35px;
    line-height: 35px;
    display: flex;
    padding-left: 30px;
    background: #baeaf8;
}
.c-address-view-li-head span{
    display: inline-block;
}
.c-address-view-li-head-text{
    font-size: 16px;
    font-weight: bold;
}
.c-checkbox{
    display: flex !important;
    height: 35px;
    line-height: 35px;
    width: 60px;
    justify-content: space-around;
    align-items: center;
}
.c-address-view-li-head span.c-checkbox{
    margin-right: 30px;
}
.c-address-view-li-body{
    width: 100%;
    max-height: 500px;
    overflow: auto;
}
.c-address-view-li-body-checkbox{
    height: 35px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 31px;
    transition: all 0.2s;
    cursor: pointer;
}
.c-address-view-li-body-checkbox:hover, .c-address-view-li-body-checkbox-select{
    background: #fff2f1;
    color: #FF5001;
}
.c-address-view-li-body-checkbox i{
    margin-right: 20px;
}
.c-address-view1{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.c-address-view1-li{
    width: 250px;
    margin: 0 10px 20px 0;
    border-radius: 0 0 3px 3px;
    border-top: 0;
}
.c-address-view1-li-head{
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 100%;
    background: #FE5554;
    color: #fff;
    border: 1px solid #FE5554;
}
.c-address-view1-li-body{
    width: 100%;
    display: flex;
    background: #fff;
    border: 1px solid #ddd;
    margin-bottom: -1px;
}
.c-address-view1-li-body-fl{
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #ddd;
    writing-mode: vertical-rl;
    padding: 10px 0;
}
.c-address-view1-li-body-fr{
    width: calc(100% - 30px);
    min-height: 90px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
}
.c-address-view1-li-body-fr span{
    display: inline-block;
    margin: 0 3px;
}

*{outline:none;}

.scroll-bar::-webkit-scrollbar {
    width: 5px;
    height: 1px;
}

.scroll-bar::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(110, 110, 110, 0.2);
    background: #535353;
}

.scroll-bar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(110, 110, 110, 0.2);
    border-radius: 5px;
    background: #EDEDED;
}


.c-form {
    clear: both;
    margin-bottom: 20px;
}
.c-form .c-form-label {
    float: left;
    display: block;
    padding-right: 10px;
    height: 35px;
    line-height: 35px;
    width: 110px;
    font-weight: 400;
    text-align: right;
    margin: 0;
    color: #000;
}
.c-form .c-form-div {
    margin-left: 120px;
    width: 375px;
    min-height: 35px;
}
.c-form .c-form-div input.c-input1, .c-form .c-form-div select.c-select1, .c-form .c-form-div textarea {
    width: 100%;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    border-radius: 4px;
    height: 35px;
    border: 1px solid #ddd;
    padding: 0 6px;
    font-size: 14px;
    outline: none;
}
.c-form .c-form-div input.c-input1:focus, .c-form .c-form-div select.c-select1:focus, .c-form .c-form-div textarea:focus, .commodityAdd-select input:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
}
.c-table {
    width: 100%;
    overflow: auto;
}

.c-model{

}
.c-model-head{
    width: 100%;
    clear: both;
    border-bottom: 1px solid #ddd;
    padding: 0 10px;
}
.c-model-head-title{
    float: left;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    color: #0A0A0A;
}
.c-model-body{
    padding: 30px;
    border-bottom: 1px solid #ddd;
}
.c-model-foot{
    padding: 20px;
    clear: both;
    text-align: right;
}

.c-model-foot-style{
    border-top: 1px solid #eaeaea;
    text-align: center;
    position: fixed;
    bottom: 24vh;
    width: 40vw;
    background: #fff;
}
.commodityAdd-title{
    width: 100%;
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #ddd;
}
.commodityAdd-select{
    display: inline-block;
}
.commodityAdd-select span{
    float: left;
    line-height: 30px;
    color: #777;
}
.commodityAdd-select input{
    border-radius: 4px;
    height: 30px;
    border: 1px solid #ddd;
    padding: 0 6px;
    font-size: 14px;
    color: #0A0A0A;
    border-radius: 3px 0 0 3px;
    width: 250px;
    float: left;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.commodityAdd-select button{
    background: #FF5001;
    color: #fff;
    width: 65px;
    height: 30px;
    border: 1px solid #FF5001;
    border-radius: 0 3px 3px 0;
    font-size: 14px;
    float: left;
}
.commodityAdd-body{
    position: relative;
}
.commodityAdd-nav{
    position: absolute;
    display: block;
    width: 108px;
    font-weight: 400;
    text-align: left;
    margin: 0;
    color: #000;
    left: 0;
    bottom: 0;
    top: 0;
    overflow: auto;
}
.commodityAdd-nav ul li{
    height: 35px;
    line-height: 35px;
    cursor: pointer;
    padding: 0 10px 0 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.commodityAdd-nav ul li:hover, .commodityAdd-nav ul li.navOn{
    color: #FF5001 !important;
}
.commodityAdd-hr{
    position: absolute;
    left: 109px;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #ddd;
}
.commodityAdd-body-content{
    padding: 10px 10px 10px 120px;
    width: 100%;
    height: 400px;
    overflow: auto;
}
.commodityAdd-body-content table{
    width: 100%;
}
/*.commodityAdd-body-content table thead tr th{*/
/*    border: 1px solid #666;*/
/*    height: 35px;*/
/*    text-align: left;*/
/*    padding-left: 10px;*/
/*}*/
/*.commodityAdd-body-content table tbody tr td{*/
/*    border: 1px solid #666;*/
/*    height: 30px;*/
/*    text-align: left;*/
/*    padding-left: 10px;*/
/*}*/

/*.commodityAdd-body-content table tbody tr:hover, .commodityAdd-body-content table tbody tr.commodity-li-on{*/
/*    background: rgba(255, 81, 81, 0.41);*/
/*}*/
.modal-class .modal-lg{
    width: 80%;
    max-width: 1024px;
}

.lh35{
    line-height: 35px;
}

.lh50{
    line-height: 50px;
}


.c-book{
    background: #fff;
}

.c-book-top{
    position: relative;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
}
.c-book-top-classify{
    margin-right: 400px;
}
.c-book-top-classify ul{
    margin: 0;
    padding: 0;
}
.c-book-top-classify ul li{
    float: left;
    padding: 0 10px;
    outline: none;
}
.c-book-top-classify ul li span{
    display: inline-block;
    height: 30px;
    line-height: 30px;
    padding: 0 15px;
    border: 1px solid #ddd;
    border-radius: 3px;
    color: #0A0A0A;
    cursor: pointer;
}
.c-book-top-classify ul li span:hover, .c-book-top-classify ul li.classifyOn span{
    color: #FF5001;
    border: 1px solid #FF5001;
}
.c-book-top-select{
    position: absolute;
    right: 20px;
    top: 10px;
}
.c-book-body{
    position: relative;
    padding-top: 20px;
}
.c-book-body-nav{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 140px;
    border-right: 1px solid #f3f3f3;
    overflow: auto;
    padding-top: 10px;
}
.c-book-body-nav ul{
    margin: 0;
    padding: 0;
}
.c-book-body-nav ul li{
    height: 35px;
    line-height: 35px;
    padding: 0 10px;
    text-align: center;
    color: #0A0A0A;
}
.c-book-body-nav ul li.bookNavOn, .c-book-body-nav ul li:hover{
    color: #FF5001;
}
.c-book-body-content{
    margin-left: 140px;
    min-height: 280px;
}
.c-book-body-content-top{
    padding: 0 20px 20px 20px;
}
.c-book-body-content-top ul{
    margin: 0;
    padding: 0;
}
.c-book-body-content-top ul li.c-book-sku{
    float: left;
    width: 20%;
    padding: 11px;
}
.c-book-sku-img{
    width: 100%;
    height: 200px;
    line-height: 200px;
    border: 1px solid #f3f3f3;
    border-radius: 3px;
    transition: all .3s ease;
}
.c-book-body-content-top ul li.c-book-sku:hover .c-book-sku-img{
    box-shadow: 0px 0px 15px rgba(21, 19, 19, 0.31);
}
.c-book-sku-img img{
    max-width: 100%;
    max-height: 100%;
}
.c-book-sku-text{
    text-align: center;
    margin: 15px 0 0 0;
    font-size: 14px;
    outline:none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.c-book-skuNo{
    text-align: center;
    font-size: 20px;
    color: #999;
    padding: 50px 0;
}

.c-book-foot-title{
    border-top: 1px solid #ddd;
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    font-weight: 600;
    color: #232323;
    padding-left: 30px;
}
.c-book-foot-title1{
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    font-weight: 600;
    color: #232323;
    padding-left: 30px;
}

.c-book-foot-item{
    border-top: 1px solid #ddd;
    padding: 30px 30px 35px 30px;
}
.c-book-foot-item ul{
    margin: 0;
    padding: 0;
}
.c-book-foot-item ul li{
    float: left;
    margin-right: 50px;
    font-size: 14px;
    color: #232323;
}
.lh35{
    line-height: 35px;
}
.c-body{
    background: #fff;
}
.price{
    color: #ff6c5f !important;
}
td.notTable{
    padding: 20px 0 !important; color: #999 !important;font-size: 16px;
}
.dropdown-menu>li>a{
    overflow: hidden;
}

.c-title{
    height: 50px;
    line-height: 50px;
    background: #fff;
    padding: 0 20px;
}
.c-title-menu{
    display: inline-block;
    height: 50px;
    color: #FF5001;
    border-bottom: 3px solid #FF5001;
    font-size: 14px;
    cursor: pointer;
    padding: 0 5px;
}

.btn_addPic{
    display: inline-block;
    position: relative;
    width: 104px;
    height: 104px;
    overflow: hidden;
    cursor: pointer;
}
.btn_addPic img{
    width: 100%;
}
.btn_addPic .filePrew{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 100px; /* 增大不同浏览器的可点击区域 */
    opacity: 0; /* 实现的关键点 */
    filter:alpha(opacity=0);/* 兼容IE */
}
.btn_addPic input[type='file'], .btn-3dfile input[type='file'] {
    z-index: 1;
}

.btn_addPic2 {
    position: relative;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn_addPic2 video {
    max-width: 100%;
    max-height: 100%;
}

.btn_addPic2 i {
    position: absolute;
    right: -20px;
    top: 50%;
    margin-top: -10px;
    cursor: pointer;
}

.btn-3dfile{
    display: flex;
    position: relative;
    width: 104px;
    height: 40px;
    overflow: hidden;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.btn-3dfile .filePrew{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 100px; /* 增大不同浏览器的可点击区域 */
    opacity: 0; /* 实现的关键点 */
    filter:alpha(opacity=0);/* 兼容IE */
}
.btn-3dfile span{
    font-size: 14px;
}
.ui-pnotify{
    z-index: 999999 !important;
}

.sel-true {
    padding: 14px;
    background: url("../images/Selected.svg") no-repeat center;
}

.sel-false {
    padding: 14px;
    background: url("../images/select.svg") no-repeat center;
}

.exit-box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.exit-box-mask {
    width: 110vw;
    height: 110vh;
    background: #000;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
}

.exit-box-con {
    width: 30vw;
    margin: 35vh auto;
    min-height: 22vh;
    background: #fff;
    border-radius: 5px;
    position: relative;
    text-align: center;
}

.back-btn1 {
    background: #fff;
    height: 50px;
    color: #232323;
    font-size: 14px;
    font-weight: bold;
    border-right: 1px solid #dfdfdf;
    border-bottom-left-radius: 5px;
}

.back-btn2 {
    background: #FAC779;
    height: 50px;
    font-size: 14px;
    font-weight: bold;
    border-bottom-right-radius: 5px;
}
.activity-queue-btn{
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
    line-height: 35px;
}
.activity-queue-view{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.activity-queue-li{
    display: inline-block;
    width: 120px;
    line-height: 35px;
    height: 35px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    color: #999;
    padding-right: 6px;
}

.orderAgreement-view{
    height: 30px;
    display: flex;
    align-items: center;
}
.orderAgreement-view-li{
    margin-right: 15px;
    height: 30px;
    display: flex;
    align-items: center;
}
.orderAgreement-view-li img{
    width: 16px;
    margin-right: 3px;
}
.orderAgreement-view-btn{
    display: inline-block;
    color: rgb(255, 80, 1);
    text-decoration: underline;
    cursor: pointer;
}

.c-video-modal {
    position: absolute;
    left: 15px;
    top: 0;
    bottom: 0;
    width: 104px;
    height: 104px;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    z-index: 1;
}

.c-video-modal img {
    width: 40px;
}

.c-video-modal span {
    font-size: 18px;
    font-weight: bold;
}

.product-pic-span{
    display: inline-block;
    position: relative;
    height: 140px;
}

.product-pic-span input{
    width: 100px;
    height: 30px;
    font-size: 12px;
    display: inline-block;
    position: absolute;
    bottom: 8px;
    left: 0;
}

.sign{
    position: relative;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    margin-left: 20px;
}
.sign .sign-img{
    cursor: pointer;
}
.sign-text{
    position: absolute;
    left: 30px;
    top: 0;
    width: 440px;
    padding: 10px;
    border: 1px solid #cecece;
    border-radius: 5px;
    background: #F8F8F8;
    color: #232323;
    font-size: 12px;
    text-align: left;
    line-height: 20px;
    display: none;
    z-index: 999;
}
.sign:hover .sign-text{
    display: block;
}
.slh {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis; }
/*商品关联场景*/
.c-scene-view{
    position: relative;
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
}
.c-scene-view:last-child{
    border-bottom: 0;
}
.c-scene-view1{
    border: 1px solid #ddd;
    width: 50%;
    border-radius: 6px;
    margin-bottom: 10px;
}
.c-scene-view-img{
    min-width: 60px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}
.c-scene-view-img img{
    max-height: 100%;
    max-width: 100%;
}
.c-scene-view-name{
    height: 60px;
    width: 80%;
    line-height: 60px;
}
.c-scene-view-icon{
    position: absolute;
    right: 5px;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
}
